@font-face {
  font-family: museosans;
  font-weight: 100;
  font-style: normal;
  src: url("./fonts/museosans/museosans-100.woff2") format("woff2");
}

@font-face {
  font-family: museosans;
  font-weight: 300;
  font-style: normal;
  src: url("./fonts/museosans/museosans-300.woff2") format("woff2");
}

@font-face {
  font-family: museosans;
  font-weight: normal;
  font-style: normal;
  src: url("./fonts/museosans/museosans-500.woff2") format("woff2");
}

@font-face {
  font-family: museosans;
  font-weight: 700;
  font-style: normal;
  src: url("./fonts/museosans/museosans-700.woff2") format("woff2");
}

@font-face {
  font-family: museosans;
  font-weight: 900;
  font-style: normal;
  src: url("./fonts/museosans/museosans-900.woff2") format("woff2");
}
