.section {
  padding: 0 var(--size-30);
  font-size: var(--font-size-3);
  width: 100%;
}

/* Cannot use var(--max-page-width) in media query */
@media (min-width: 600px) {
  .innerWrapper {
    margin: 0 auto;
    width: var(--max-page-width);
    padding: 0 16px;
  }
}

.light {
  background-color: var(--background-color-light);
}

.dark {
  background-color: #0e0a2b;
}
