.container {
  width: 100%;
  text-align: center;
  margin-bottom: var(--size-30) 0;
  padding: var(--size-30) 0;
  border-bottom: 1px solid var(--color-border-light);
}

.innerWrapper {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  justify-content: space-between;
}

.logo {
  display: flex;
  align-items: center;
}
